import * as React from "react";
import { AuthState, NotificationType, UserState } from "../user/UserState";
import { Auth } from "@awsscm/awsscm-auth-manager/auth/AuthManager";
import Icon from "@amzn/awsui-components-react-v3/polaris/icon";
import { Box, Button, FormField, Input, Link, Modal, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { NotificationBox, resetMessageStatus } from "../notification/NotificationBox";
import "./LoginPanel.scss";
import "../AWS_Infrastructure.scss";
import { Header } from "./Header";

export enum AuthenticationChallengeType {
  MFA,
  PASSWORD_REST
}

export interface AuthenticationChallengePanelProps {
  setUserState: (userState: UserState) => void,
  userState: UserState,
  maxAllowedAttempts: number
}

/**
 * React component to handle authentication challenge for user login and password reset flow.
 * @param props component props.
 */
export const AuthenticationChallengePanel = (props: AuthenticationChallengePanelProps) => {
  // below lines are added to temporarily satisfy the line coverage stats, need to do actual implementation later
  const [authCode, setAuthCode] = React.useState<string>("");
  const [attempts, setAttempts] = React.useState<number>(1);
  const [signInLoading, setSignInLoading] = React.useState<boolean>(false);

  const submitButtonEnabled = () : boolean => {
    return authCode !== "";
  };

  const submitConfirmationCode = (confirmationCode: string) => {
    setSignInLoading(true);
      Auth.respondAuthChallenge(confirmationCode, props.userState.user)
        .then(() => props.setUserState({
          authenticated: true,
          authState: AuthState.SIGN_IN,
          user: props.userState.user,
          message: null
        }))
        .catch((e : Error) => {
          setSignInLoading(false);
          if (attempts >= props.maxAllowedAttempts || e.name === "NotAuthorizedException") {
            props.setUserState({
              authenticated: false,
              authState: AuthState.SIGN_IN,
              user: props.userState.user,
              message: {
                message: "Sign in failed because the authentication code cannot be verified or session expired.",
                type: NotificationType.ERROR
              }
            });
          } else {
            setAttempts(attempts + 1);
            const remainingAttempts = props.maxAllowedAttempts - attempts;
            props.setUserState({
              authenticated: false,
              authState: AuthState.MFA,
              user: props.userState.user,
              message: {
                message: "Incorrect authentication code, please retry. Remaining attempts: " + remainingAttempts,
                type: NotificationType.ERROR
              }
            });
          }
        });
  }

  return (
    <div className={"awsscm-argo-authentication-challenge-panel"}>
      <Modal
        size={"small"}
        visible={props.userState.authState === AuthState.MFA}
        // close the modal should navigate back to main login page
        onDismiss={(event) => {
          if (event.detail.reason === 'closeButton') {
            props.setUserState({
              authenticated: false,
              authState: AuthState.SIGN_IN,
              user: props.userState.user,
              message: null
            })
          }
        }}
        closeAriaLabel="Close modal"
        // headers
        header={<Header/>}
      >
        <SpaceBetween size={"m"} direction={"vertical"}>
          <div>
            <b> An authentication code was sent to {props.userState.user?.getUsername()} . </b>
            <br/>
            <span className={"awsscm-try-again-message"}>
              Please try again if you don't receive email within 5 minutes.
            </span>
          </div>

          <FormField>
            <Input
              onChange={({detail}) => {
                resetMessageStatus({userState: props.userState, setUserState: props.setUserState});
                setAuthCode(detail.value);
              }}
              value={authCode}
              onKeyDown={(event) => {
                if (event.detail.keyCode === 13) {
                  submitConfirmationCode(authCode);
                }
              }}
              placeholder="Authentication Code"/>
          </FormField>

          <NotificationBox userState={props.userState} setUserState={props.setUserState}/>

          <Box textAlign={"center"}>
              <Button variant="primary" onClick={() => submitConfirmationCode(authCode)}
                      disabled={!submitButtonEnabled()}
                      loading={signInLoading}>
                Submit
              </Button>
          </Box>
        </SpaceBetween>
        <br/>
        <span >
          <Icon name="status-info" variant="link"/>
          <Link className={"awsscm-trouble-sign-in-message"} href="mailto:infra-portal-login@amazon.com" variant="secondary">
            Having trouble signing in?
          </Link>
        </span>
      </Modal>
    </div>);
}
